import React, { useEffect } from 'react'
import Seo from '../../components/molecules/seo'
import Layout from '../../components/molecules/layout'

const Integrations = (props) => {
  const title = 'integrations'
  useEffect(() => {
    const widget = document.createElement('script')
    widget.type = 'text/javascript'
    widget.async = true
    widget.src =
      'https://zapier.com/apps/embed/widget.js?services=phonetonote&limit=10&html_id=zapier-widget'
    document.body.appendChild(widget)
  }, [])

  return (
    <Layout location={props.location}>
      <Seo title={title} />
      <section className="hero is-primary">
        <div className="hero-body">
          <h1 className="page-title">{title}</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <main className="content">
                <h2>fetch your messages with secure feeds</h2>
                <p>
                  your unsynced messages are available in private{' '}
                  <strong>rss and json</strong> feeds.
                </p>

                <h2>update your feed with our API</h2>
                <p>
                  mark items as syncing or synced with our API. full
                  documentation is coming soon, but for now please use the
                  repl.it environment to get started.
                </p>
              </main>
            </div>
            <div className="column is-4">
              <iframe
                frameBorder="0"
                width="100%"
                height="500px"
                title="phonetonote-replit"
                src="https://replit.com/@phonetonote/phonetonote-starter?embed=true"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="columns">
            <div className="column is-12 content">
              <h2>zapier</h2>
              <p>
                use zapier to pipe messages into your tools for thought, or use
                our zapier to add and remove unsynced items from your feed.
                fetch your items using zapier's RSS reader.
              </p>
              <div id="zapier-widget"></div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Integrations
